<template>
  <pdl-button
    v-bind="compareButtonIconProps"
    qaid="productCompareAddButton"
    :block="block"
    :text="!tertiary"
    :tertiary="tertiary"
    :small="small"
    class="product-compare-button"
    @click.stop="onCompareClick"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    {{ compareButtonText }}
  </pdl-button>
</template>
<script>
import {CompareButton} from '@/components/compare/mixins/compare-button';
import {PdlButton} from '@pedal/pdl-button';

export default {
  name: 'ProductCompareButton',
  components: {PdlButton},
  mixins: [CompareButton],

  props: {
    product: {
      type: Object,
      required: true,
    },
    block: {
      type: Boolean,
      default: false,
    },
    tertiary: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      imageUrl: '',
      productName: '',
      productUrl: '',
      productCode: '',
      baseProductId: '',
    };
  },

  computed: {
    isBuyingZoneCompare() {
      return !Array.isArray(this.product.images);
    },
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.imageUrl = this.product?.imageURLList ? this.product?.imageURLList[0] : '';

      if (!this.imageUrl && this.product && this.product?.images) {
        this.imageUrl = this.getImageUrl();
      }

      this.productName = this.product.displayName || this.product.name;
      this.productUrl = this.product.url || window.location.pathname;
      this.baseProductId = this.productCode = this.product.code;
    },

    getImageUrl() {
      if (!this.product) return;
      if (this.isBuyingZoneCompare) {
        const assetId = this.product.images[Object.keys(this.product.images)[0]][0].assetId;
        return `//media.trekbikes.com/image/upload/f_auto,q_auto,w_1360,h_1020,fl_strip_profile/${assetId}`;
      } else {
        return this.product?.images[0]?.url;
      }
    },
  },
};
</script>

<style lang="scss">
.product-compare-button.button--text {
  span {
    text-decoration: none;
    box-shadow: 0 1px 0 0 transparent;
  }

  &:hover {
    span {
      box-shadow: 0 1px 0 0 var(--gray-100);
    }
  }
}
</style>
